'use client';
/* eslint-disable tsdoc/syntax */
/**
 * @file Provides a context and provider for managing sort and filter state used
 * in the EventsCommunitiesPaginatedListCn component. The context includes both
 * a `orderBy` value for sorting and a `searchString` for textual search, as
 * well as setter functions for each.
 *
 * @remarks
 * This file assumes the \{"strict": true\} compiler option is enabled in your
 * project's TypeScript configuration.
 */

import { type Type } from '@demeter/musiclocal-relay-v4';
import React, {
  createContext,
  useState,
  useContext,
  type ReactNode,
} from 'react';

/**
 * The shape of the sort and filter context value.
 */
type EventsCommunitiesFiltersContextValue = {
  /**
   * Current sort order for the events.
   */
  orderBy: Type.EventsCommunitiesOrderBy;

  /**
   * Current search string for filtering events.
   */
  searchString: string;

  /**
   * Setter for updating the `orderBy` sort.
   */
  setOrderBy: (value: Type.EventsCommunitiesOrderBy) => void;

  /**
   * Setter for updating the `searchString`.
   */
  setSearchString: (value: string) => void;
};

/**
 * Initializes a React context for the events communities filters.
 * Defaults are `{ orderBy: 'SORT_KEY_ASC', searchString: '', setOrderBy: () => undefined, setSearchString: () => undefined }`.
 */
const EventsCommunitiesFiltersContext =
  createContext<EventsCommunitiesFiltersContextValue>({
    orderBy: 'SORT_KEY_ASC',
    searchString: '',
    setOrderBy: () => undefined,
    setSearchString: () => undefined,
  });

/**
 * Provider component to manage the `orderBy` and `searchString` states within its children.
 *
 * @param children React children to render within the provider.
 *
 * @example
 * ```tsx
 * <EventsCommunitiesFiltersProvider>
 *   <YourComponent />
 * </EventsCommunitiesFiltersProvider>
 * ```
 *
 * @remarks
 * This component allows consuming components to read and update both the sorting
 * order and the search string.
 */
export function EventsCommunitiesFiltersProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [orderBy, setOrderBy] =
    useState<Type.EventsCommunitiesOrderBy>('SORT_KEY_ASC');
  const [searchString, setSearchString] = useState('');

  return (
    <EventsCommunitiesFiltersContext.Provider
      value={{
        orderBy,
        setOrderBy,
        searchString,
        setSearchString,
      }}
    >
      {children}
    </EventsCommunitiesFiltersContext.Provider>
  );
}

/**
 * Custom hook to access and update the events communities filters.
 *
 * @returns The context value, including the current `orderBy`, `searchString`,
 * and setter functions for each.
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const { orderBy, setOrderBy, searchString, setSearchString } = useEventsCommunitiesFilters();
 *   return (
 *     <div>
 *       <p>Current Order: {orderBy}</p>
 *       <p>Current Search: {searchString}</p>
 *       <button onClick={() => setOrderBy("EVENT_BY_EVENT_ID__START_DATE_ASC")}>
 *         Order by start date
 *       </button>
 *       <input
 *         value={searchString}
 *         onChange={(e) => setSearchString(e.target.value)}
 *         placeholder="Search events"
 *       />
 *     </div>
 *   );
 * }
 * ```
 *
 * @throws Error if the hook is called outside of the EventsCommunitiesFiltersProvider.
 */
export function useEventsCommunitiesFilters(): EventsCommunitiesFiltersContextValue {
  const contextValue = useContext(EventsCommunitiesFiltersContext);
  if (!contextValue) {
    throw new Error(
      'useEventsCommunitiesFilters must be used within an EventsCommunitiesFiltersProvider',
    );
  }
  return contextValue;
}
